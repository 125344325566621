<template>
  <section class="bando-head">
    <h1>Nous contacter</h1>
  </section>

  <div class="container">
    <section class="content">
      <p>
        Pour tout problème technique, veuillez cliquer
        <a href="mailto:rdv-fse@mesmedicamentschezmoi.com">ici</a>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "MentionsLegales",
  components: {}
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 5% 5rem;

  @media screen and (min-width: 992px) {
    padding: 0 10% 5rem;
  }

  .title {
    margin: 4rem 0 3rem;
  }

  p {
    @media screen and (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
}
</style>
